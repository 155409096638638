import { graphql, useStaticQuery } from "gatsby";
import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Lottie from "react-lottie";
import RightArrow from "../assets/icons/chevron-right.svg";
import Contact from "../components/contact";
import Header from "../components/Header";
import Layout from "../components/layout";
import WhatWeDo from "../lotties/what-we-do";
import MobileDev from "../media/mobile-dev.png";
import WebDev from "../media/web-dev.png";
import styles from "../styles/services.module.scss";

const whatWeDoAnimation = {
  loop: true,
  autoplay: true,
  animationData: WhatWeDo,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const Services = () => {
  const data = useStaticQuery(graphql`
    query {
      allFile(filter: { relativeDirectory: { eq: "assets/icons/tech" } }) {
        edges {
          node {
            name
            publicURL
          }
        }
      }
    }
  `);

  let techIcons = data.allFile.edges;
  let dividedTechIcons = [];

  while (techIcons.length) {
    dividedTechIcons.push(techIcons.splice(0, 7));
  }
  while (dividedTechIcons.length) {
    techIcons.push(dividedTechIcons.splice(0, 3));
  }

  const nextIcons = () => {
    let iconsSet = document.getElementsByClassName(styles.iconsList);
    iconsSet = Array.from(iconsSet);

    for (let i = 0; i < iconsSet.length; i++) {
      if (iconsSet[i].classList.contains(styles.show)) {
        iconsSet[i].classList.toggle(styles.show);

        if (i === iconsSet.length - 1) {
          iconsSet[0].classList.toggle(styles.show);
        } else {
          iconsSet[i + 1].classList.toggle(styles.show);
        }

        break;
      }
    }
  };

  const prevIcons = () => {
    let iconsSet = document.getElementsByClassName(styles.iconsList);
    iconsSet = Array.from(iconsSet);

    for (let i = 0; i < iconsSet.length; i++) {
      if (iconsSet[i].classList.contains(styles.show)) {
        iconsSet[i].classList.toggle(styles.show);

        if (i === 0) {
          iconsSet[iconsSet.length - 1].classList.toggle(styles.show);
        } else {
          iconsSet[i - 1].classList.toggle(styles.show);
        }

        break;
      }
    }
  };

  // to focus the div element containing the contents
  const ready = () => {
    document.getElementsByClassName(styles.services)[0].focus();
  };

  useEffect(() => {
    if (document.readyState === "loading") {
      document.addEventListener("DOMContentLoaded", ready);
    } else {
      ready();
    }
  }, []);

  return (
    <Layout
      title="Services | Mahusai"
      description="We build everything from simple websites to comprehensive web and mobile applications. We cover the entire process from conceptualization, design, prototyping, development, testing and QA, deployment, all the way to support and maintenance."
      pathname="/services/"
    >
      <div
        className={styles.services}
        // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
        tabIndex={0}
      >
        <Header />

        <Container fluid>
          <Row xs={1} className={styles.row}>
            <Col xs md lg={{ span: 7 }} className="col-height-mobile">
              <div className="details-section center-middle-position">
                <div className="title">What we do</div>
                <div className="description">
                  <p>
                    We build everything from simple websites to comprehensive
                    web and mobile applications. We cover the entire process
                    from conceptualization, design, prototyping, development,
                    testing and QA, deployment, all the way to support and
                    maintenance.
                  </p>
                  <p>
                    We use the best and most reliable technologies to provide
                    solutions that are secure, bulletproof, and futureproof.
                  </p>
                </div>
              </div>
              {/* <div className={`${styles.details} ${styles.centerMiddle}`}>
                <div className={styles.title}>What we do</div>
                <div className={styles.intro}>
                  <p>
                    We build everything from simple websites to comprehensive
                    web and mobile applications. We cover the entire process
                    from conceptualization, design, prototyping, development,
                    testing and QA, deployment, all the way to support and
                    maintenance.
                  </p>
                  <p>
                    We use the best and most reliable technologies to provide
                    solutions that are secure, bulletproof, and futureproof.
                  </p>
                </div>
              </div> */}
            </Col>
            <Col xs md lg={{ span: 5 }}>
              <div className={`${styles.centerMiddle} ${styles.animation}`}>
                <Lottie
                  options={whatWeDoAnimation}
                  width="100%"
                  height="100%"
                />
              </div>
            </Col>
          </Row>

          <Row xs={1} className={`${styles.row} ${styles.webDev}`}>
            <Col lg={{ span: 7 }}>
              <img src={WebDev} alt="webdev" className={styles.centerMiddle} />
            </Col>
            <Col lg={{ span: 5 }}>
              <div className={styles.webDevDescription}>
                <div className={`${styles.description} ${styles.centerMiddle}`}>
                  We make web apps, websites, and pretty much anything on the
                  internet, full-stack, using any piece of technology your heart
                  desires.
                </div>
              </div>
            </Col>
          </Row>

          <Row xs={1} className={`${styles.row} ${styles.mobileDev}`}>
            <Col
              xs={{ order: "last" }}
              md={{ order: "last" }}
              lg={{ order: "first", span: 7 }}
            >
              <img src={MobileDev} alt="mobile" />
            </Col>
            <Col lg={{ span: 5 }}>
              <div className={`${styles.description} ${styles.centerMiddle}`}>
                We make mobile apps on any and all devices - Android or iOS -
                because we know that the best software is the one that's with
                you.
              </div>
            </Col>
          </Row>

          <Row xs={1} className={`${styles.row} ${styles.uiUX}`}>
            <Col lg={{ span: 9 }}></Col>
            <Col lg={{ span: 3 }}>
              <div
                className={`${styles.description} ${styles.uiUXDescription} ${styles.whiteColor}`}
              >
                We make art and design. We craft beauty, usability, and
                practicality into every bit and pixel we create. When it comes
                to software design, you know we got you covered.
              </div>
            </Col>
          </Row>

          <Row className={`${styles.row} ${styles.techsUsed}`}>
            <Container fluid>
              <Row xs={1} md={12} className={styles.space}>
                <Col md={{ span: 10 }}>
                  <div className={styles.techTitle}>Technologies we use</div>
                </Col>
              </Row>
              {techIcons.map((page, i) => {
                return (
                  <Row
                    key={`row_${i}`}
                    xs={2}
                    md={7}
                    className={`${styles.iconsList} ${styles.space} ${
                      i === 0 ? styles.show : ""
                    }`}
                  >
                    {page.map((row) => {
                      return row.map((tech, i) => {
                        if (tech.node) {
                          if (i === row.length - 1) {
                            return (
                              <Col
                                key={`col_${i}`}
                                md
                                className={styles.lastIcon}
                              >
                                <div
                                  key={`div_${i}`}
                                  className={`${styles.icon} ${styles.centerMiddle}`}
                                >
                                  <img
                                    key={`img_${i}`}
                                    src={tech.node.publicURL}
                                    alt={tech.node.name}
                                  />
                                  <span
                                    key={`span_${i}`}
                                    className={styles.portfolioName}
                                  >
                                    {tech.node.name}
                                  </span>
                                </div>
                              </Col>
                            );
                          }
                          return (
                            <Col key={`col_${i}`} md>
                              <div
                                key={`div_${i}`}
                                className={`${styles.icon} ${styles.centerMiddle}`}
                              >
                                <img
                                  key={`img_${i}`}
                                  src={tech.node.publicURL}
                                  alt={tech.node.name}
                                />
                                <span
                                  key={`span_${i}`}
                                  className={styles.portfolioName}
                                >
                                  {tech.node.name}
                                </span>
                              </div>
                            </Col>
                          );
                        }
                        return null;
                      });
                    })}
                  </Row>
                );
              })}
              <Row xs={2}>
                <Col md className={styles.prevButton}>
                  <button onClick={prevIcons}>
                    <RightArrow className={styles.arrow} /> PREVIOUS
                  </button>
                </Col>
                <Col md className={`${styles.moreButton} text-right`}>
                  <button onClick={nextIcons}>
                    NEXT <RightArrow className={styles.arrow} />
                  </button>
                </Col>
              </Row>
            </Container>
          </Row>
        </Container>

        <Contact
          bgColorLeft="#C3E4F6"
          fontColorLeft="#002E5E"
          bgColorRight="#002E5E"
          fontColorRight="#FFFFFF"
          linkColor="#0C59AC"
        />
      </div>
    </Layout>
  );
};

export default Services;
